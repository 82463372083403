import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import { _usersGet } from "../services/apiConnections.service";

export const Users = () => {

    const [users, setUsers] = useState([]);
    const [emptyTable, setEmptyTable] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!users.length && !emptyTable) {
            getData();
        }
    }, [users, emptyTable]);

    const getData = async () => {
        let result = await _usersGet();
        if (result.error) {
            console.log("--error:", result.error);
        } else {
            setEmptyTable(!result.users.length);
            setUsers(result.users);
        }
        setError(result.error);
    };

    const columns = [
        {
            dataField: 'email',
            text: 'Email',
            sort: true
        },
        {
            dataField: 'balance',
            text: 'Balance',
            sort: true
        }
    ];

    const defaultSorted = [
        {
            dataField: 'email',
            order: 'asc'
        }
    ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total"> Showing {from} to {to} of {size} Results</span>
    );

    const options = {
        hidePageListOnlyOnePage: true,
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [
            {
                text: '10', value: 10
            },
            {
                text: '25', value: 25
            },
            {
                text: '50', value: 50
            },
            {
                text: '100', value: 100
            }
        ]
    };

    const noData = () => {
        return <p className='emptyTableMessage'>No matching records found</p>
    };
    return (
        <div className='content'>
            <div>
                <div className='pageTitle'>
                    <span>Users</span>
                </div>
                {error && <p className='msgBlock errorMsg'>{error}</p>}
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='myTable'>
                            <div className='tableHeader'>
                            </div>
                            <BootstrapTable
                                keyField='ID'
                                data={users}
                                columns={columns}
                                wrapperClasses='table-responsive'
                                bordered={false}
                                pagination={paginationFactory(options)}
                                defaultSorted={defaultSorted}
                                noDataIndication={noData} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};