import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { MenuLink } from "./menuLink.component";

export const SideMenu = () => {

    const location = useLocation();
    const [url, setUrl] = useState(null);

    useEffect(() => {
        setUrl(location.pathname);
    }, [location]);

    return (
        <div className='sideMenu'>
            <ul>
                <li className={(url === '/users' ? 'activeMenuLink' : '')}>
                    <MenuLink to='/users' icon='users' name='Users' />
                </li>
                <li className={(url === '/prices' ? 'activeMenuLink' : '')}>
                    <MenuLink to='/prices' icon='prices' name='Prices' />
                </li>
                <li className={(url === '/tariffs' ? 'activeMenuLink' : '')}>
                    <MenuLink to='/tariffs' icon='tariffs' name='Tariffs' />
                </li>
            </ul>
        </div>
    );
};