import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useCSVReader } from 'react-papaparse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { _pricesGet, _pricesUpload } from "../services/apiConnections.service";

export const Prices = () => {

    const [prices, setPrices] = useState([]);
    const [emptyTable, setEmptyTable] = useState(false);
    const [error, setError] = useState(null);
    const [uploadedPrices, setUploadedPrices] = useState([]);

    const { CSVReader } = useCSVReader();

    useEffect(() => {
        if (!prices.length && !emptyTable) {
            getData();
        }
    }, [prices, emptyTable]);

    const getData = async () => {
        let result = await _pricesGet();
        if (result.error) {
            console.log("--error:", result.error);
        } else {
            setEmptyTable(!result.price_list.prices.length);
            setPrices(result.price_list.prices);
        }
        setError(result.error);
    };

    const hadlePricesUploads = (uploadedPrices) => {
        let result = [];
        let data = uploadedPrices.data;
        let keys = ['name', 'direction', 'cost'];
        for (let line = 1; line < data.length; line++) {
            let price = {};
            for (let cell = 0; cell < data[line].length; cell++) {
                if (data[line][cell].length) {
                    price[keys[cell]] = data[line][cell];
                }
            }
            if (Object.keys(price).length !== 0) {
                result.push(price);
            }
        }
        setUploadedPrices(result);
        console.log(result);
    };

    const uploadPricesToServer = async () => {
        let data = {
            "start_at": new Date().toISOString(),
            "prices": uploadedPrices
        }
        let result = await _pricesUpload(data);
        if (result.error) {
            console.log("--error:", result.error);
        } else {
            getData();
        }
        setError(result.error);
    };

    const columns = [
        {
            dataField: 'name',
            text: 'Destination',
            sort: true
        },
        {
            dataField: 'direction',
            text: 'Code',
            sort: true
        },
        {
            dataField: 'cost',
            text: 'Price/SMS',
            sort: true,
            sortFunc: (a, b, order) => {
                if (order === "asc") {
                    return Number(a) - Number(b);
                } else if (order === "desc") {
                    return Number(b) - Number(a);
                }
            }
        }
    ];

    const defaultSorted = [
        {
            dataField: 'name',
            order: 'asc'
        }
    ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total"> Showing {from} to {to} of {size} Results</span>
    );

    const options = {
        hidePageListOnlyOnePage: true,
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [
            {
                text: '10', value: 10
            },
            {
                text: '25', value: 25
            },
            {
                text: '50', value: 50
            },
            {
                text: '100', value: 100
            }
        ]
    };

    const noData = () => {
        return <p className='emptyTableMessage'>No matching records found</p>
    };
    return (
        <div className='content'>
            <div>
                <div className='pageTitle'>
                    <span>Prices</span>
                </div>
                {error && <p className='msgBlock errorMsg'>{error}</p>}
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='myTable'>
                            <div className='tableHeader'>
                                <div className="mb-3">
                                    <CSVReader
                                        onUploadAccepted={(results) => { hadlePricesUploads(results) }}>
                                        {
                                            (
                                                { getRootProps,
                                                    acceptedFile,
                                                    ProgressBar,
                                                    getRemoveFileProps }
                                            ) => (
                                                <div>
                                                    <div className='fileUploadWrapper'>
                                                        <button
                                                            type='button' {...getRootProps()}
                                                            className={"browseFileBtn btn " + (acceptedFile ? 'acceptedFile' : '')}>Upload Prices</button>
                                                        {acceptedFile &&
                                                            <div className='fileName'>
                                                                <span>{acceptedFile && acceptedFile.name}</span>
                                                            </div>
                                                        }
                                                        {acceptedFile &&
                                                            <div className="fileUploadButtonsBlock">
                                                                <button
                                                                    {...getRemoveFileProps()}
                                                                    className='removeFileBtn btn'>
                                                                    <FontAwesomeIcon icon={regular('trash-can')} />
                                                                </button>
                                                                <button
                                                                    onClick={uploadPricesToServer}
                                                                    className='uploadFileBtn btn'>
                                                                    <FontAwesomeIcon icon={solid('arrow-up-from-bracket')} />
                                                                </button>
                                                            </div>
                                                        }
                                                    </div>
                                                    <ProgressBar />
                                                </div>
                                            )
                                        }
                                    </CSVReader>
                                </div>
                            </div>
                            <BootstrapTable
                                keyField='ID'
                                data={prices}
                                columns={columns}
                                wrapperClasses='table-responsive'
                                bordered={false}
                                pagination={paginationFactory(options)}
                                defaultSorted={defaultSorted}
                                noDataIndication={noData} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};