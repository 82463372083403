import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Routes, Route, useNavigate } from 'react-router-dom';

import { MainPage } from './pages/Main.page';
import { Users } from './pages/Users.page';
import { Prices } from './pages/Prices.page';
import { Tariffs } from './pages/Tariffs.page';
import { TariffPlanUpsert } from './pages/TariffPlanUpsert.page';

const App = () => {

  let navigate = useNavigate();

  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<MainPage navigate={navigate} />} >
          <Route path='users' element={<Users />} />
          <Route path='prices' element={<Prices />} />
          <Route path='tariffs' element={<Tariffs />} />
          <Route path='add-tariff-plan' element={<TariffPlanUpsert />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
