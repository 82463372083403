import { HandleError } from "./errorHandler.service";

//const apiUrl = window.API_URL || 'https://sms.testmc.mcore.solutions/';
const apiUrl = window.API_URL || window.location.origin;


const url = `${apiUrl.replace(/\/+$/, "")}/api/v1`; 

console.log(url);

export const _usersGet = async () => {
    return await _get(`/users`);
};

export const _pricesGet = async () => {
    return await _get(`/prices`);
};

export const _pricesUpload = async (data) => {
    return await _fetch(data, `/prices`);
};

export const _tariffsGet = async () => {
    return await _get(`/tariff-packages`);
};

export const _tariffPlanAdd = async (data) => {
    const dataToSend = {
        "name": data.name,
        "allowed_directions": data.destination.filter((e) => { return e }),
        "denied_destinations": data.deny_destination.filter((e) => { return e }),
        "start_date": data.start_date,
        "end_date": data.end_date,
        "cost": data.price.toString(),
        "validity_period": parseInt(data.validity_time),
        "sms_limit": parseInt(data.sms_limit),
        "description": data.description
    };
    return await _fetch(dataToSend, `/tariff-packages`);
};

export const _tariffPlanEdit = async (data, id) => {

};

export const _tariffPlanDelete = async (id) => {
    return await _delete(`/tariff-packages/${id}`);
};

const _get = async (route) => {
    try {
        const response = await fetch(url + route, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        console.log(response);
        if (!response.ok) HandleError(response);
        const json = await response.json();
        return json;
    } catch (error) {
        console.log(`--${route} error: ${error}`);
        console.log(error);
        console.log(error.response.status);
    }
};

const _fetch = async (data = {}, route = '', put = false) => {
    try {
        const response = await fetch(url + route, {
            method: put ? 'PUT' : 'POST',
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });
        const json = await response.json();
        if (!response.ok) HandleError(response);
        return json;
    } catch (error) {
        console.log(`--${route} error: ${error}`);
    }
};

const _delete = async (route) => {
    const response = await fetch(url + route, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        }
    });
    console.log("response:", response);
    if (!response.ok) HandleError(response);
};