import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { _tariffPlanDelete, _tariffsGet } from "../services/apiConnections.service";

import { ModalDialog } from "../components/ModalDialog.component";

export const Tariffs = () => {

    const [tariffs, setTariffs] = useState([]);
    const [emptyTable, setEmptyTable] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [tariffToDelete, setTariffToDelete] = useState({});
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!tariffs.length && !emptyTable) {
            getData();
        }
    }, [tariffs, emptyTable]);

    const getData = async () => {
        let result = await _tariffsGet();
        if (result.error) {
            console.log("--error:", result.error);
        } else {
            setEmptyTable(!result.tariff_packages.length);
            setTariffs(result.tariff_packages);
        }
        setError(result.error);
    };

    const destinationFormatter = (cell, row) => {
        return cell.toString();
    };

    const deleteFormatter = (cell, row) => {
        return (
            <button
                className='tableIconBtn trashBtn'
                onClick={() => {
                    setTariffToDelete(row);
                    setShowDeleteDialog(true);
                }}>
                <FontAwesomeIcon icon={regular('trash-can')} />
            </button>
        )
    };

    const handleDeleteTariff = async (id) => {
        await _tariffPlanDelete(id);
        getData();
        setShowDeleteDialog(false);
    };

    const columns = [
        {
            dataField: 'name',
            text: 'Name',
            sort: true
        },
        {
            dataField: 'allowed_directions',
            text: 'Destination',
            formatter: destinationFormatter
        },
        {
            dataField: 'cost',
            text: 'Price',
            sort: true,
            sortFunc: (a, b, order) => {
                if (order === "asc") {
                    return Number(a) - Number(b);
                } else if (order === "desc") {
                    return Number(b) - Number(a);
                }
            }
        },
        {
            dataField: 'sms_limit',
            text: 'SMS Limit'
        },
        {
            dataField: 'validity_period',
            text: 'Validity Time',
        },
        {
            dataField: 'description',
            text: 'Description',
        },
        {
            dataField: 'user_id',
            text: 'Delete',
            classes: 'iconColumn',
            formatter: deleteFormatter
        }
    ];

    const defaultSorted = [
        {
            dataField: 'name',
            order: 'asc'
        }
    ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total"> Showing {from} to {to} of {size} Results</span>
    );

    const options = {
        hidePageListOnlyOnePage: true,
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [
            {
                text: '10', value: 10
            },
            {
                text: '25', value: 25
            },
            {
                text: '50', value: 50
            },
            {
                text: '100', value: 100
            }
        ]
    };

    const noData = () => {
        return <p className='emptyTableMessage'>No matching records found</p>
    };
    return (
        <div className='content'>
            <div>
                <div className='pageTitle'>
                    <span>Tariffs</span>
                </div>
                {error && <p className='msgBlock errorMsg'>{error}</p>}
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='myTable'>
                            <div className='tableHeader'>
                                <Link className='mainBtn btn' to={'/add-tariff-plan'}>Add Tariff Plan</Link>
                            </div>
                            <BootstrapTable
                                keyField='ID'
                                data={tariffs}
                                columns={columns}
                                wrapperClasses='table-responsive'
                                bordered={false}
                                pagination={paginationFactory(options)}
                                defaultSorted={defaultSorted}
                                noDataIndication={noData} />
                        </div>
                    </div>
                </div>
                <ModalDialog
                    show={showDeleteDialog}
                    onHide={() => setShowDeleteDialog(false)}
                    onAccept={async () => handleDeleteTariff(tariffToDelete.ID)}
                    title={`Delete tariff ${tariffToDelete.name}`}
                    acceptBtnText="Delete">
                    <span>{`Are you sure you want to delete tariff '${tariffToDelete.name}'?`}</span>
                </ModalDialog>
            </div>
        </div>
    )
};