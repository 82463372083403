export const HandleError = (response) => {
    let status = response.status;
    console.log("response", response);

    console.log("status", status);
    switch (status) {
        case 401: {
            getCookies();
            break;
        }
        default:
            break;
    }
};

const getCookies = () => {
    let thisUrl = window.location.href;
    thisUrl = thisUrl.substring(0, thisUrl.lastIndexOf("/"));
    let prefix = window.SNAME || "sms."
    let mcoreUrl = thisUrl.replace(prefix, "");
    console.log("thisURL", thisUrl);
    console.log("mcoreURL", mcoreUrl);
    window.location.href = `${mcoreUrl}/login.xhtml?next=${thisUrl}`;
};